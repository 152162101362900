<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{},
				hero:{},
				count_package:{},
				contact:''
            }
		},
		computed:{
			myParams(){
				let query = Gen.clone(this.$route.query)
				return query
			}
		},
		async mounted() {
			await this.ready();
            this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 300)
            this.getApi()
            this.apiGetStaticHero()
		},
		methods: {
			async getStaticHero(id){
	            return (await Gen.apirest('/static-hero', {id:id}, ()=>{})).data
	        },
	        async apiGetStaticHero(){
	            this.hero = await this.getStaticHero(1)
	            setTimeout(()=>{
	            	SEMICOLON.documentOnLoad.init()
	            },500)
	        },
        	getApi() {
				Gen.apirest("/list-package",this.myParams,(err,resp)=>{
					if(err) console.log(err)
					this.data = resp.data
					this.count_package = resp.count_package
					this.contact = resp.contact
					setTimeout(()=>{
		            	SEMICOLON.documentOnLoad.init()
		            },500)
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
        },
        watch: {
			$route(){
				this.getApi()
			}
		}
	};
</script>
<template>
	<div>
		<section id="slider" class="slider-element boxed-slider notoppadding">
		    <div class="clearfix">
		        <div class="fslider" data-touch="false" data-easing="easeInQuad">
		            <div class="flexslider">
		                <div class="slider-wrap">
							<div v-if="!isMobile" class="slide" :data-thumb="uploader(hero.img_desktop)">
								<img :src="uploader(hero.img_desktop)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';font-size:'+hero.ash_title_size+'px;'">{{ hero.title }}</h2>
									<p :style="'font-size:'+hero.ash_sub_title_size+'px;color:'+hero.ash_sub_title_color+';font-family:'+hero.ash_sub_title_font_type+';'" v-if="hero.sub_title">{{hero.sub_title}}</p>
								</div>
							</div>
							<div v-else class="slide" :data-thumb="uploader(hero.img_mobile)">
								<img :src="uploader(hero.img_mobile)" :alt="hero.title" :title="hero.title">
								<div class="flex-caption slider-caption-bg">
									<h2 :style="'color:'+hero.ash_title_color+';font-family:'+hero.ash_title_font_type+';'">{{ hero.title }}</h2>
								</div>
							</div>
						</div>
		            </div>
		        </div>
		    </div>
		</section>
		<section id="content" style="background-color: #000; overflow: visible">
			<div class="content-wrap pt-0 pb-0">
				<div id="section-about" class="section page-section bg_white nobottommargin notopmargin clearfix">
					<div class="container clearfix">
						<div class="row  justify-content-center ">
							<div class="col-md-12">
								<ol class="breadcrumb">
									<li class="breadcrumb-item">
										<router-link :to="{name:'Home'}">
											{{ web.mn_home }}
										</router-link>
									</li>
									<li class="breadcrumb-item active" aria-current="page">{{ web.lbl_special_event }}</li>
								</ol>
							</div>
							<h3 v-if="count_package<1" style="color:#fff" class="text-center">{{ web.lbl_no_data }}</h3>
							<div v-else v-for="(v,k) in data.data" :key="k" class="col-md-6">
								<div class="pacakge_wrap">
									<div class="img_wine">
										<img style="width: 100%;" :src="uploader(v.ap_image)" :alt="v.name" :title="v.name">
									</div>
									<h3 v-if="v.name">{{ v.name }}</h3>
									<p>{{ v.price }} / {{ v.ap_pax }} PAX</p>
									<span v-html="v.description"></span>
								</div>
								<a class="btn_order" :href="'https://api.whatsapp.com/send?phone=62'+contact" target="_blank">{{web.lbl_contact}} <i class="icon-line-arrow-right"></i></a>
							</div>
						</div>
					</div>
				</div>
				<div class="clear"></div>
			</div>
		</section>
	</div>
</template>